export const RENT_SKY = [
  {
    id: 300509,
    title: "Комплект: 2 радиостанций Baofeng BF-888s	",
    img: "raciya_baofeng_bf-888s_komplekt_2.png",
    desc: "работает в частотах от 400-470 МГц",
    category: "rent_sky",
    type: "dif",
    price: 10,
    column: [
      "Baofeng BF-888S [2 Pack] – комплект из 2 миниатюрных раций с дальностью связи до 7 километров",
      "Каждое устройство оснащено емким аккумулятором 1500 мАч, что гарантирует до 10 часов автономной работы рации на одном заряде аккумулятора",
      "Комплект поставки: 2 х Радиостанция / 2 х Антенна / 2 х Аккумулятор / 2 х Клипса на пояс / 2 х Гарнитура / 2 х Зарядное устройство / Инструкция",
      "Страна производитель: КИТАЙ",
      "Мощность	5 Вт",
      "Размеры устройства: 115x60x33 мм",
      "Количество каналов: 16",
      "Рабочая температура: От -20 °C до +60 °C",
      "Питание: Li-ion аккумулятор, 3.7 В",
      "Вес изделия: 620 г",
    ],
  },
  {
    id: 30087509,
    title: "ЛЫЖИ ",
    img: "5.45.27.jpg",
    desc: "ДЛЯ ФРИРАЙДА",
    category: "rent_sky",
    type: "sky",
    price: 45,
    column: ["KASTLE FX 95 DUAL RISE 173"],
  },
  {
    id: 30009,
    title: "Лыжи",
    img: "19.08.22.jpg",
    desc: "от 90 см до 177 см",
    category: "rent_sky",
    type: "sky",
    price: 25,
    column: [
      "Atomic 90 см",
      "Atomic funmachine 95",
      "Volkl vertigo 110 см",
      "Carver4you 120 см",
      "Fischer RC4 128 cм",
      "Rossignol B1 junior 130 см",
      "Fischer DRS 102Stream 148 см",
      "Volkl energy 148 см",
      "Fischer RCX 150 см",
      "Nordica gransport10 152 см",
      "Head C770 intelligence 156 см",
      "Rossignol WorldCup 160 см",
      "Rossignol Saphir 160 cм",
      "Fischer Inspire Koa 160 см",
      "Nordica sportmachine 160 см",
      "K2 160 см",
      "Volkl crosstiger 161 см",
      "Dynastar Driver x06 162 см",
      "Volkl racetiger 163 см",
      "Head XRC 163 см",
      "Tecnopro rpx200 165 см",
      "K2 168 см",
      "Elan Amphibioti 13 168 см",
      "Elan Magfire 82 168 см ",
      "Volkl racetiger 168 см",
      "Volkl RaceCarver P60 168 см",
      "Volkl Aqua 170 см",
      "Fischer Viron XTR 170 см",
      "Volkl racetiger 173 см",
      "Salomon Scrambler 175 см",
      "Head X-shape 177 см",
    ],
  },
  {
    id: 300039,
    title: "Ботинки лыжные",
    img: "19.39.01.png",
    desc: "размеры от 22.5 см до 29.5 см",
    category: "rent_sky",
    type: "sky",
    price: 30,
    column: [
      "Размер: 22.5 см — размер 35, 23 см — размер 36, 23.5 см — размер 37, 24 см — размер 37.5, 24.5 см — размер 38, 25 - 25.5 см — размер 39, 26.5 см — размер 40, 27 см — размер 41, 27 - 27.5 см — размер 42, 28 см — размер 43, 28.5 см — размер 44, 29 см — размер 45, 29.5 см — размер 46",
    ],
  },
  {
    id: 3040035,
    title: "Ботинки + лыжи",
    img: "19.45.11.jpg",
    desc: "от 90 см до 177 см",
    category: "rent_sky",
    type: "set",
    price: 45,
    column: [
      "Atomic 90 см",
      "Atomic funmachine 95",
      "Volkl vertigo 110 см",
      "Carver4you 120 см",
      "Fischer RC4 128 cм",
      "Rossignol B1 junior 130 см",
      "Fischer DRS 102Stream 148 см",
      "Volkl energy 148 см",
      "Fischer RCX 150 см",
      "Nordica gransport10 152 см",
      "Head C770 intelligence 156 см",
      "Rossignol WorldCup 160 см",
      "Rossignol Saphir 160 cм",
      "Fischer Inspire Koa 160 см",
      "Nordica sportmachine 160 см",
      "K2 160 см",
      "Volkl crosstiger 161 см",
      "Dynastar Driver x06 162 см",
      "Volkl racetiger 163 см",
      "Head XRC 163 см",
      "Tecnopro rpx200 165 см",
      "K2 168 см",
      "Elan Amphibioti 13 168 см",
      "Elan Magfire 82 168 см ",
      "Volkl racetiger 168 см",
      "Volkl RaceCarver P60 168 см",
      "Volkl Aqua 170 см",
      "Fischer Viron XTR 170 см",
      "Volkl racetiger 173 см",
      "Salomon Scrambler 175 см",
      "Head X-shape 177 см",
      "Размер: 22.5 см — размер 35, 23 см — размер 36, 23.5 см — размер 37, 24 см — размер 37.5, 24.5 см — размер 38, 25 - 25.5 см — размер 39, 26.5 см — размер 40, 27 см — размер 41, 27 - 27.5 см — размер 42, 28 см — размер 43, 28.5 см — размер 44, 29 см — размер 45, 29.5 см — размер 46",
    ],
  },
  {
    id: 3000354,
    title: "Лыжи + ботинки + палки",
    img: "2.37.20.jpg",
    desc: "от 90 см до 177 см",
    category: "rent_sky",
    type: "set",
    price: 50,
    column: [
      "Atomic 90 см",
      "Atomic funmachine 95",
      "Volkl vertigo 110 см",
      "Carver4you 120 см",
      "Fischer RC4 128 cм",
      "Rossignol B1 junior 130 см",
      "Fischer DRS 102Stream 148 см",
      "Volkl energy 148 см",
      "Fischer RCX 150 см",
      "Nordica gransport10 152 см",
      "Head C770 intelligence 156 см",
      "Rossignol WorldCup 160 см",
      "Rossignol Saphir 160 cм",
      "Fischer Inspire Koa 160 см",
      "Nordica sportmachine 160 см",
      "K2 160 см",
      "Volkl crosstiger 161 см",
      "Dynastar Driver x06 162 см",
      "Volkl racetiger 163 см",
      "Head XRC 163 см",
      "Tecnopro rpx200 165 см",
      "K2 168 см",
      "Elan Amphibioti 13 168 см",
      "Elan Magfire 82 168 см ",
      "Volkl racetiger 168 см",
      "Volkl RaceCarver P60 168 см",
      "Volkl Aqua 170 см",
      "Fischer Viron XTR 170 см",
      "Volkl racetiger 173 см",
      "Salomon Scrambler 175 см",
      "Head X-shape 177 см",
      "Размер: 22.5 см — размер 35, 23 см — размер 36, 23.5 см — размер 37, 24 см — размер 37.5, 24.5 см — размер 38, 25 - 25.5 см — размер 39, 26.5 см — размер 40, 27 см — размер 41, 27 - 27.5 см — размер 42, 28 см — размер 43, 28.5 см — размер 44, 29 см — размер 45, 29.5 см — размер 46",
    ],
  },
  {
    id: 30003544,
    title: "Лыжи + ботинки + маска + шлем",
    img: "22.52.19.jpg",
    desc: "от 90 см до 177 см",
    category: "rent_sky",
    type: "set",
    price: 70,
    column: [
      "Размер шлема: S, M, L",
      "Размер маски: S, M, L ",
      "Размер ботинок: 22.5 см — размер 35, 23 см — размер 36, 23.5 см — размер 37, 24 см — размер 37.5, 24.5 см — размер 38, 25 - 25.5 см — размер 39, 26.5 см — размер 40, 27 см — размер 41, 27 - 27.5 см — размер 42, 28 см — размер 43, 28.5 см — размер 44, 29 см — размер 45, 29.5 см — размер 46.",
      "Лыжи:",
      "Atomic 90 см",
      "Atomic funmachine 95",
      "Volkl vertigo 110 см",
      "Carver4you 120 см",
      "Fischer RC4 128 cм",
      "Rossignol B1 junior 130 см",
      "Fischer DRS 102Stream 148 см",
      "Volkl energy 148 см",
      "Fischer RCX 150 см",
      "Nordica gransport10 152 см",
      "Head C770 intelligence 156 см",
      "Rossignol WorldCup 160 см",
      "Rossignol Saphir 160 cм",
      "Fischer Inspire Koa 160 см",
      "Nordica sportmachine 160 см",
      "K2 160 см",
      "Volkl crosstiger 161 см",
      "Dynastar Driver x06 162 см",
      "Volkl racetiger 163 см",
      "Head XRC 163 см",
      "Tecnopro rpx200 165 см",
      "K2 168 см",
      "Elan Amphibioti 13 168 см",
      "Elan Magfire 82 168 см ",
      "Volkl racetiger 168 см",
      "Volkl RaceCarver P60 168 см",
      "Volkl Aqua 170 см",
      "Fischer Viron XTR 170 см",
      "Volkl racetiger 173 см",
      "Salomon Scrambler 175 см",
      "Head X-shape 177 см",
    ],
  },
  {
    id: 300010,
    title: "Сноуборд",
    img: "19.52.32.jpg",
    desc: "от 95 см до 166 см",
    category: "rent_sky",
    type: "snowboard",
    price: 40,
    column: [
      "Nidecker 95 см",
      "Black Fire 110 см",
      "Raisign 130 см",
      "Nidecker Mini Angel 139 см",
      "Morrow wildflower 140 см",
      "Oxygen Fascination 144 см",
      "Ride OMG 144 см",
      "Elan chica’s 146 см",
      "Division23 147 см",
      "Capita The Outsiders 148 см",
      "Nitro Сinema TwinModel 149 см",
      "Palmer 150 см",
      "Black Fire (BF) elusive 150 см",
      "Nidecker 152 см",
      "Elan Altrocker 152 см",
      "Step Child 152 см",
      "Salomon Classic 152 см",
      "Rossignol  temptation 153",
      "Burton clash 153 см",
      "Drake Banger 153 см",
      "Terror OnlyTheBrave 153 см",
      "K2 AtlasFormat 153 см",
      "Schwinn 156 см",
      "Stuf Surge 156 см",
      "Rossignol circuit 156 см",
      "Drake League 156 см",
      "Technine T9 157 cм",
      "Black Fire (BF) Gothic 157 см",
      "Trans 157 см",
      "Rome SDS 158 см",
      "Nitro Punisher 158 cм",
      "Burton Custom 158 см",
      "USD Pro muerte 159 см",
      "Volkl stroke 160 см",
      "Black Fire (BF) Falcon 160 см",
      "Technine 161 см",
      "Forum 163 см",
      "Black Fire (BF) 166 см",
      "Nitro Option redline 166 см",
    ],
  },
  {
    id: 300011,
    title: "Ботинки сноубордические",
    img: "19.19.03.jpg",
    desc: "размеры от 34 до 47",
    category: "rent_sky",
    type: "snowboard",
    price: 30,
    column: [
      "Размер ботинок: 34, 35, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47",
    ],
  },
  {
    id: 300035,
    title: "Сноуборд + ботинки",
    img: "9.54.26.jpg",
    desc: "от 95 см до 166 см",
    category: "rent_sky",
    type: "set",
    price: 50,
    column: [
      "Размер ботинок: 34, 35, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47",
      "Nidecker 95 см",
      "Black Fire 110 см",
      "Raisign 130 см",
      "Nidecker Mini Angel 139 см",
      "Morrow wildflower 140 см",
      "Oxygen Fascination 144 см",
      "Ride OMG 144 см",
      "Elan chica’s 146 см",
      "Division23 147 см",
      "Capita The Outsiders 148 см",
      "Nitro Сinema TwinModel 149 см",
      "Palmer 150 см",
      "Black Fire (BF) elusive 150 см",
      "Nidecker 152 см",
      "Elan Altrocker 152 см",
      "Step Child 152 см",
      "Salomon Classic 152 см",
      "Rossignol  temptation 153",
      "Burton clash 153 см",
      "Drake Banger 153 см",
      "Terror OnlyTheBrave 153 см",
      "K2 AtlasFormat 153 см",
      "Schwinn 156 см",
      "Stuf Surge 156 см",
      "Rossignol circuit 156 см",
      "Drake League 156 см",
      "Technine T9 157 cм",
      "Black Fire (BF) Gothic 157 см",
      "Trans 157 см",
      "Rome SDS 158 см",
      "Nitro Punisher 158 cм",
      "Burton Custom 158 см",
      "USD Pro muerte 159 см",
      "Volkl stroke 160 см",
      "Black Fire (BF) Falcon 160 см",
      "Technine 161 см",
      "Forum 163 см",
      "Black Fire (BF) 166 см",
      "Nitro Option redline 166 см",
    ],
  },
  {
    id: 3000398,
    title: "Сноуборд + ботинки + маска + шлем",
    img: "22.58.51.jpg",
    desc: "от 95 см до 166 см",
    category: "rent_sky",
    type: "set",
    price: 70,
    column: [
      "Размер шлема: S, M, L",
      "Размер маски: S, M, L ",
      "Размер ботинок: 34, 35, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47",
      "Сноуборды:",
      "Nidecker 95 см",
      "Black Fire 110 см",
      "Raisign 130 см",
      "Nidecker Mini Angel 139 см",
      "Morrow wildflower 140 см",
      "Oxygen Fascination 144 см",
      "Ride OMG 144 см",
      "Elan chica’s 146 см",
      "Division23 147 см",
      "Capita The Outsiders 148 см",
      "Nitro Сinema TwinModel 149 см",
      "Palmer 150 см",
      "Black Fire (BF) elusive 150 см",
      "Nidecker 152 см",
      "Elan Altrocker 152 см",
      "Step Child 152 см",
      "Salomon Classic 152 см",
      "Rossignol  temptation 153",
      "Burton clash 153 см",
      "Drake Banger 153 см",
      "Terror OnlyTheBrave 153 см",
      "K2 AtlasFormat 153 см",
      "Schwinn 156 см",
      "Stuf Surge 156 см",
      "Rossignol circuit 156 см",
      "Drake League 156 см",
      "Technine T9 157 cм",
      "Black Fire (BF) Gothic 157 см",
      "Trans 157 см",
      "Rome SDS 158 см",
      "Nitro Punisher 158 cм",
      "Burton Custom 158 см",
      "USD Pro muerte 159 см",
      "Volkl stroke 160 см",
      "Black Fire (BF) Falcon 160 см",
      "Technine 161 см",
      "Forum 163 см",
      "Black Fire (BF) 166 см",
      "Nitro Option redline 166 см",
    ],
  },
  {
    id: 30001,
    title: "Куртка горнолыжная",
    img: "IMG_5871.JPG",
    desc: "мужская",
    category: "rent_sky",
    type: "jacket",
    price: 15,
    column: ["Размер:XS, S, M, L, XL, XXL"],
  },
  {
    id: 30002,
    title: "Куртка горнолыжная",
    img: "IMG_5888.JPG",
    desc: "женская",
    category: "rent_sky",
    type: "jacket",
    price: 15,
    column: ["Размер:XS, S, M, L, XL, XXL"],
  },
  {
    id: 30004,
    title: "Штаны горнолыжные",
    img: "IMG_5870.JPG",
    desc: "мужские",
    category: "rent_sky",
    type: "pants",
    price: 10,
    column: [
      "Размер:S, M, L, XL, XXL",
      "Большинство штанов в нейтральных цветах и подходят для парней и девушек одновременно",
    ],
  },
  {
    id: 304005,
    title: "Штаны горнолыжные",
    img: "9.31.40.png",
    desc: "женские",
    category: "rent_sky",
    type: "pants",
    price: 10,
    column: [
      "Размер:S, M, L, XL, XXL",
      "Большинство штанов в нейтральных цветах и подходят для парней и девушек одновременно",
    ],
  },
  {
    id: 30005,
    title: "Куртка + штаны",
    img: "23.03.31.jpg",
    desc: "XS-XXL",
    category: "rent_sky",
    type: "set",
    price: 25,
    column: [
      "Размеры женских штанов: S, M, L",
      "Размеры мужских штанов: S, M, L, XL, XXL",
      "Размер мужских курток: XS, S, M, L, XL, XXL",
      "Размер женских курток: XS, S, M, L, XL",
      "Большинство штанов в нейтральных цветах и подходят для парней и девушек одновременно",
    ],
  },
  {
    id: 300045,
    title: "Комбинезон",
    img: "IMG_5903.PNG",
    desc: "S-L",
    category: "rent_sky",
    type: "overall",
    price: 20,
    column: ["Размеры:S, L"],
  },
  {
    id: 30440045,
    title: "комбинезон",
    img: "ezgif-4-f35c20e8f8.jpg",
    desc: "детский | подростковый ",
    category: "rent_sky",
    type: "overall",
    price: 15,
    column: [""],
  },
  {
    id: 30006,
    title: "Горнолыжные перчатки",
    img: "IMG_586.png",
    desc: "универсальные",
    category: "rent_sky",
    type: "gloves",
    price: 5,
    column: ["Размер:XS, S, M, L"],
  },
  {
    id: 30007,
    title: "Шлем защитный",
    img: "IMG_58.png",
    desc: "универсальный",
    category: "rent_sky",
    type: "helmet",
    price: 15,
    column: ["Размер:S, M, L"],
  },
  {
    id: 30008,
    title: "Маска горнолыжная",
    img: "IMG_5865-.png",
    desc: "универсальная",
    category: "rent_sky",
    type: "mask",
    price: 10,
    column: [
      "100% защита от ультрафиолета. Желтое стекло. Двойное стекло с противозапотевающей обработкой «BASIC».",
      "Размер: S, M, L.",
    ],
  },
  {
    id: 300082,
    title: "Тюбинг",
    img: "Gn31w.jpg",
    desc: "120 и 90 и 75 см",
    category: "rent_sky",
    type: "dif",
    price: 25,
    column: [],
  },
  {
    id: 30033082,
    title: "Пластиковые санки",
    img: "s1.webp",
    desc: "детские",
    category: "rent_sky",
    type: "dif",
    price: 20,
    column: [
      "Размер:	65x40x13 см",
      "Максимальная нагрузка:	50 кг",
      "Вес:	1.340 кг",
      "Полиэтиленовые пластиковые снежные сани высокой плотности предназначены для удержания сильного на любой местности и построены для сгибания без трещин даже при самых холодных температурах.",
    ],
  },
  {
    id: 300333082,
    title: "Пластиковые санки",
    img: "S1-14.webp",
    desc: "детские",
    category: "rent_sky",
    type: "dif",
    price: 15,
    column: ["Длина:	127 см"],
  },
  {
    id: 300015,
    title: "Защита копчика",
    img: "19.24.57.jpg",
    desc: "",
    category: "rent_sky",
    type: "protection",
    price: 10,
    column: [],
  },

  {
    id: 3000156,
    title: "Защита спины",
    img: "ezgif-2-bbe929db9a.jpg",
    desc: "",
    category: "rent_sky",
    type: "protection",
    price: 10,
    column: [],
  },
  {
    id: 3000157,
    title: "Защита коленей, локтей, запястья",
    img: "19.28.22.jpg",
    desc: "",
    category: "rent_sky",
    type: "protection",
    price: 15,
    column: [],
  },
];
